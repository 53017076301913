body{font-family: Plus Jakarta Sans!important;color: #141414!important;  background: #F4F5F7!important;}
* { margin: 0px; padding: 0px; box-sizing: border-box; }
a{text-decoration: none;}
h1, h2, h3, h4, h5, h6, p, ul{margin: 0px; padding: 0px;}
:focus-visible { outline: none;} 

.chat_list {display: grid;grid-template-columns: repeat(3,1fr);margin: 0 -15px;}
.chat_list .card_bx {border-radius: 5px;background: #FFF;box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);padding: 20px;margin: 0 15px 30px;}
.chat_list .card_bx h3{color: #111;font-size: 14px;font-weight: 600;letter-spacing: 0.28px;margin-bottom: 8px;text-transform: uppercase;}
.chat_list .card_bx p {color: #FE5722;font-size: 24px;font-weight: 700;margin-bottom: 15px;}
.chat_list .card_bx .line{background: linear-gradient(270deg, #FE5722 1.75%, #FE5722 100%);display: block;height: 4px;width: 100%;margin-bottom: 25px;}
.chat_list .card_bx:nth-child(2) .line{background: linear-gradient(270deg, #00AFA5 1.75%, #00AFA5 100%);}
.chat_list .card_bx:nth-child(3) .line{background: linear-gradient(270deg, #8291FB 1.75%, #8291FB 100%);}
.chat_list .card_bx:nth-child(4) .line{background: linear-gradient(270deg, #794DF6 1.75%, #BC01CD 100%);}
.chat_list .card_bx:nth-child(5) .line{background: linear-gradient(270deg, #071DE9 1.75%, #FFB422 100%);}
.chat_list .card_bx:nth-child(6) .line {background: linear-gradient(90deg, #1A2980 0%, #26D0CE 100%);}

.grap_list .grap_card {background: #FFF;box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);margin: 0 15px;}

.grap_list {display: grid;grid-template-columns: 1fr 0.6fr;margin: 0 -15px;}

.grap_head h3 {color: #111;font-size: 14px;font-weight: 700;letter-spacing: 0.28px;text-transform: uppercase;}

.grap_head ul {}

.grap_head ul li {display: inline-block;position: relative;padding-left: 40px;color: #666;font-size: 12px;font-weight: 400;}
.grap_head {display: flex;align-items: center;justify-content: space-between;padding: 20px 20px 30px;}
.grap_chart {padding: 15px;}
.grap_line .grap_chart img {width: 100%;}
.grap_head ul li:after {border-radius: 20px;background: #9C27B0;width: 12px;height: 12px;position: absolute;left: 13px;top: 5px;content: '';z-index: 1;}
.grap_head ul li:before {border-radius: 20px;background: #FFF;width: 20px;height: 20px;position: absolute;left: 8px;top: 0px;border-radius: 20px;background: #FFF;box-shadow: 2px 1px 1px 0px rgba(141, 140, 140, 0.17);z-index: 0;content: '';}
.grap_head ul li:nth-child(3):after {background:#0F7FF8;}
.grap_head ul li:nth-child(2):after {background:#E3BD9E;}
.grap_head h4 {text-align: center;position: absolute;right: 30px;top: 18%;color: #111;font-size: 14px;font-weight: 700;text-transform: uppercase;}
.grap_head h4 span {display: block;font-size: 28px;font-weight: 900;}
.user_grap {position: relative;}
.usergrap_point {margin-top: 50px;}
.usergrap_point ul {margin-left: 20px;}
.usergrap_point ul li {position: relative;display: inline-block;padding-left: 25px;margin-right: 15px;color: #7C7C7C;font-size: 14px;font-weight: 500;}
.usergrap_point ul li:after {position: absolute;content: '';width: 15px;height: 15px;background: #673AB7;left: 0;border-radius: 4px;top: 6px;box-shadow: 2px 1px 1px 0px rgba(141, 140, 140, 0.17);}
.usergrap_point ul li:nth-child(2):after {background: #FF5722;}
.usergrap_point ul li:nth-child(3):after {background: #2196F3;}
.card_body {background: #FFF;box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);margin-top: 30px;padding: 30px;}
.card_body .head h3 {color: #111;font-family: Plus Jakarta Sans;font-size: 14px;font-style: normal;font-weight: 700;line-height: normal;letter-spacing: 0.28px;text-transform: uppercase;}
.card_body .head {margin-bottom: 20px;}
.table_grap {}

.table_grap .table {width: 100%;    border-collapse: collapse;}
.table_grap .table thead th {color: #111;font-size: 14px;font-weight: 700;text-transform: uppercase;border-bottom: 3px solid  rgba(0, 0, 0, 0.1);padding: 12px 0;text-align: left;}
.table_grap .table tbody td {border: 0; color: #555;font-size: 14px;padding: 15px 0;font-weight: 600;border-bottom: 1px solid rgba(0, 0, 0, 0.1);}
.table_grap .table tbody td:nth-child(2) {color: #555;font-size: 13px;font-weight: 400;}
.table_grap .table tbody td:nth-child(3) {color: #994B00;font-weight: 400;}
.table_grap .table tbody td:nth-child(4) {color: #111;font-weight: 600;}
.table_grap .table tbody td:nth-child(5) {color: #555;font-size: 13px;font-weight: 400;}
.table_grap .table tbody tr:last-child td {border: 0;}
.select_icon{position: relative;}
.select_icon::after {
    content: '';
    position: absolute;
    right: 15px;
    z-index: 9;
    top: 11px;
    pointer-events: none;
    background: url(../images/fluent_ios-arrow-24-filled.png);
    width: 24px;
    height: 24px;
    background-size: contain;
    background-repeat: no-repeat;
}
.user_avtar .userimg {}

.user_avtar .userimg img {
    width: 40px;
    height: 40px;
    border-radius: 100px;
    object-fit: cover;
}
.head_top{margin-bottom: 30px;}
.alluser_sect .head_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.head_top .form-group {
    display: flex;
    align-items: center;
    gap: 10px;
}
.head_top .form-group label {
    color: #404040;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
}
.head_top .form-group .form-control {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
    border: 0;
    color: #AAA;
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
    height: 50px;width: 202px;
    padding: 8px 40px 8px 20px;
}
.table_inner table.table {
    border-collapse: collapse;
    margin: 0;width: 100%;
}
.table_inner table.table thead th:first-child {
    width: 30px;
    border-radius: 10px 0 0 10px;
}
.table_inner table.table thead th:last-child {
  border-radius: 0 10px 10px 0;padding-right: 20px;
}
.table_inner table.table thead th {
    border-top: 1px solid #F1F2F6;
    border-bottom: 1px solid #F1F2F6;
    background: #F8F9FB;
    border-radius: 0;
    color: #404040;
    font-size: 14px;
    border: 0;
    font-weight: 500;padding: 20px;
    text-transform: capitalize;
    vertical-align: middle;
}

.table_inner table.table tbody td{padding: 20px; color: #6F6B6B;font-family: Plus Jakarta Sans;font-size: 14px;font-style: normal;font-weight: 400;line-height: 150%; /* 21px */vertical-align: middle;border: 0;}

.table_sect {
    border-radius: 7px;
    background: #FFF;
}

.table_sect .table_inner {
    padding: 15px;
}

.table_inner table.table thead {}

.css-i4bv87-MuiSvgIcon-root path {
}

.action_btn .btn-success {
    border: 0;
    background: transparent;
    padding: 0;
}

.action_btn {
    display: flex;
    align-items: center;
    justify-content: end;
}

.dropdown-toggle::after {
    display: none;
}

.table_inner table.table tbody tr {
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.09);
}


.table_inner table.table tbody:before {
    line-height: 8px;
    content:".";
    display:block;
}
.table_inner table.table tbody tr:hover{background: #FFF;
box-shadow: 0px 2px 49px 0px rgba(0, 0, 0, 0.19);background: #FFF;
box-shadow: 0px 2px 49px 0px rgba(0, 0, 0, 0.19);}


.table_inner table.table {
    border-collapse: collapse;
    margin: 0;
}

.table_inner table.table thead th:first-child {
    width: 30px;
    border-radius: 10px 0 0 10px;
}
.table_inner table.table thead th:last-child {
  border-radius: 0 10px 10px 0;
}
.table_inner table.table thead th {
 
}

.table_inner table.table tbody td{color: #6F6B6B;font-family: Plus Jakarta Sans;font-size: 14px;font-style: normal;font-weight: 400;line-height: 150%; /* 21px */vertical-align: middle;border: 0;}

.table_sect {
    border-radius: 7px;
    background: #FFF;
}

.table_sect .table_inner {
    padding: 15px;
}

.action_btn .btn-success {
    border: 0;
    background: transparent;
    padding: 0;
}

.action_btn {
    display: flex;
    align-items: center;
    justify-content: end;
}

.dropdown-toggle::after {
    display: none;
}

.table_inner table.table tbody tr {
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.09);
}
tbody:before {
    line-height: 8px;
    content:".";
    display:block;
}
.table_inner table.table tbody tr:hover{background: #FFF;
box-shadow: 0px 2px 49px 0px rgba(0, 0, 0, 0.19);background: #FFF;
box-shadow: 0px 2px 49px 0px rgba(0, 0, 0, 0.19);}

.table_inner table.table tbody td .MuiAvatar-root,.table_inner table.table tbody td .css-1pqm26d-MuiAvatar-img {
    width: 38px;
    height: 38px;
    border-radius: 100px;
}

.user_avtar {
    display: flex;
    align-items: center;
}

.user_dt h3 {
    color: #0A0A0A;
    font-size: 14px;margin-bottom: 0;
    font-weight: 500;
    text-transform: capitalize;
}

.user_dt p {
    color: #747474;
    font-size: 14px;
    font-weight: 400;margin-bottom: 0;
    text-transform: capitalize;
}

.user_dt {
    margin-left: 10px;
}

.table_inner table.table thead th:last-child {
    text-align: right;
}
.table_inner table.table tbody td .active {
    background: rgba(1, 184, 26, 0.15);
    color: #01B81A;
   font-size: 12px;
    font-weight: 500;
    width: 85px;
    padding: 4px 10px;
    text-align: center;
    text-transform: capitalize;
    margin: 0 auto;
    border-radius: 800px;
    text-transform: capitalize;
}

.table_inner table.table tbody td .Inactive {
    color: #FA8B0C;
    font-size: 12px;
    font-weight: 500;
    width: 85px;
    padding: 4px 10px;
    text-align: center;
    text-transform: capitalize;
    margin: 0 auto;
    border-radius: 800px;
    background: rgba(250, 139, 12, 0.15);
}
.table_inner table.table tbody td .PendingReview{ color: #016EFF;
    font-size: 12px;
    font-weight: 500;
    width:124px;
    padding: 4px 10px;
    text-align: center;
    text-transform: capitalize;
    margin: 0 auto;
    border-radius: 800px;
    background: rgba(1, 110, 255, 0.15);}
    .table_inner table.table tbody td .Spam{ color: #D80C0C;
        font-size: 12px;
        font-weight: 500;
        width: 85px;
        padding: 4px 10px;
        text-align: center;
        text-transform: capitalize;
        margin: 0 auto;
        border-radius: 800px;
        background: rgba(250, 12, 12, 0.15);}
.table_inner table.table thead th:nth-child(5) {
    text-align: center;
}
.allpost_sect .user_avtar {
    align-items: start;
}
.AllUsersView_sect .btn_grp {
    display: inline-flex;
    justify-content: center;
    margin: 20px 0;
    text-align: center;
    width: 100%;
}
.allpost_sect .user_dt h3{margin-bottom: 8px;}
.allpost_sect .user_dt p{margin-bottom: 8px;}
.AllUsersView_sect .btn_grp .btn {
    border-radius: 49px;
    color: #984B00;
    font-size: 18px;
    font-weight: 400;
    border-radius: 49px;
    border: 1px solid #984B00;
    padding: 8px 30px;
    text-transform: capitalize;
    margin: 0 15px;
}
.AllUsersView_sect .btn_grp .btn:nth-child(2){
    background: #984B00;color:#fff;
}

.AllUsersView_sect .btn_grp .btn:hover {
    color: #fff;
    background: #984B00;
}
.pagenation_item {
    display: flex;
    align-items: center;
    justify-content: space-between;margin: 20px 0;
}

.pagenation_item h3 {
    color: #000;
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.pagenation_item .pagenation_no {
    display: flex;
    align-items: center;
}

.pagenation_item .pagenation_no ul {border-radius: 22px;padding-left: 0;margin: 0;
    background: rgba(153, 75, 0, 0.20);}

.pagenation_item .pagenation_no ul li {display: inline-block;}



.pagenation_item .pagenation_no ul {}

.pagenation_item .pagenation_no ul li {}

.pagenation_item .pagenation_no ul li button {
    border-radius: 40px;
    border: 1px solid #000;
    color: #000;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding: 4px 15px;
}

.pagenation_item .pagenation_no ul li a {
    color: #994B00;
    font-size: 18px;
    font-weight: 500;
    width: 62px;text-decoration: none;
    height: 53px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagenation_item .pagenation_no ul li button span {margin-left: 10px;
}
.AllUsersView_sect .head_top a.btn_back {
    border: 1px solid #984b00;
    border-radius: 49px;
    color: #984b00;
    font-size: 18px;
    font-weight: 400;text-decoration: none;
    padding: 8px 30px;
}
.AllUsersView_sect .head_top {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
}
.AllUsersView_sect .head_top a.btn_back:hover {
    background: #984B00;
    color: #fff;
}
.pagenation_no button {
    border-radius: 40px;
    border: 1px solid #000;
    color: #000;
    font-size: 18px;
    font-weight: 600;
    padding: 8px 20px;
    width: 150px;
    margin: 0 24px;
}
.pagenation_item .pagenation_no ul li.active a{border-radius: 22px;color: #fff;
    background: #994B00;}

    .AllUsersView_sect .card_body .head {
        border-bottom: 1px solid #E2E2E2;
    }
    .AllUsersView_sect .card_body.pd-0 {
        padding: 0;
    }
    .AllUsersView_sect .card_body {
        border-radius: 8px;
    }
    .AllUsersView_sect .card_body .head ul {padding: 0;margin: 0;}
    
    .AllUsersView_sect .card_body .head ul li {
        display: block;
        color: #111;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        padding: 15px;
        display: inline-block;
        border-bottom: 1px solid #994B00;
    }
    
    .comp_info_wrapp {
        display: grid;
        grid-template-columns: 0.6fr 1fr 0.8fr;
        gap: 30px;
        padding: 0px 30px 20px;
    }
    
    .comp_info_wrapp .crd_bx {}
    
    .comp_info_wrapp .crd_bx .image_bx {
        text-align: left;
        margin-bottom: 30px;
    }
    
    .comp_info_wrapp .crd_bx .image_bx img {
        width: 120px;
        height: 120px;
        object-fit: cover;
        border-radius: 100%;
    }
    
    .comp_info_wrapp .crd_bx .image_bx p {
        color: #111;
        font-size: 14px;
        font-weight: 700;
        text-transform: capitalize;
        margin-top: 15px;
    }
    
    .comp_info_wrapp .crd_bx .from {}
    
    .comp_info_wrapp .crd_bx .from .form-group {
        margin-bottom: 15px;
    }
    
    .comp_info_wrapp .crd_bx .from .form-group label {
        color: #464646;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
        text-transform: capitalize;
    }
    
    .comp_info_wrapp .crd_bx .from .form-group .form-control {
        border-radius: 4px;
        border: 1px solid #F1F2F6;
        background: #FFF;
        color: #A3A3A3;
        font-size: 16px;
        font-weight: 400;
        line-height: 23px; 
        text-transform: capitalize;
        height: 45px;
    }
    
    .comp_info_wrapp .crd_bx .from .form-group .react-tel-input {}
    
    .react-tel-input .form-control {
        border-radius: 4px;
        border: 1px solid #F1F2F6;
        background: #FFF;
        color: #A3A3A3;
        font-size: 16px;
        font-weight: 400;
        line-height: 23px;
        text-transform: capitalize;
        height: 45px;
        width: 100%;
    }
    
    .react-tel-input .flag-dropdown {}
    
    .comp_info_wrapp .crd_bx>p {
        color: #464646;
        font-size: 14px;
        font-weight: 500;
        text-transform: capitalize;
        margin-bottom: 15px;
    }
    
    .comp_info_wrapp .crd_bx .about_info {
        border-radius: 4px;
        border: 1px solid #F1F2F6;
        background: #FFF;
        padding: 15px;
        }
    .comp_info_wrapp .crd_bx .about_info p{
        color: #A3A3A3;
        font-size: 14px;
        line-height: 23px;
    }
    
    
    .Portfolio_list {
        display: grid;
        grid-template-columns: repeat(4,1fr);
        gap: 20px;
        padding: 15px 30px;
    }
    
    .Portfolio_list .item {
        position: relative;
        overflow: hidden;
        border-radius: 46px;
    }
    
    .Portfolio_list .item .images_bx {}
    
    .Portfolio_list .item .images_bx img {
        width: 100%;
    }
    
    .Portfolio_list .item .content {
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 1;
    }
    
    .Portfolio_list .item .content p {}
    
    .Portfolio_list .item:after {
        background: linear-gradient(359deg, #000 -45.48%, rgba(0, 0, 0, 0.61) 34.08%, rgba(255, 252, 252, 0.00) 103.14%);
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    
    .content p {
        color: #FFF;
        font-size: 24px;
        font-weight: 700;
        text-transform: capitalize;
        padding: 20px;
    }
    

.notif_sect .card_body {
        margin-top: 0;   height: 100%;
        min-height: 400px;
}
    
.notif_sect .card_body .list_notif .crd_bx {
        border-radius: 6px;
        border: 1px solid #E2E2E2;
        background: #FFF;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 15px;
    }
    
    .notif_sect .card_body .list_notif {
        width: 100%;
        max-width: 80%;
    }
    
    .notif_sect .card_body .list_notif .crd_bx h3 {
        color: #333;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }
    
    .notif_sect .card_body .list_notif .crd_bx p {
        color: #333;
        font-weight: 400;
        font-size: 16px;
    }
    .table_inner table.table tbody td .dropdown-menu.show {
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
        width: 69px!important;
    }
    
    .table_inner table.table tbody td .dropdown-menu.show .dropdown-item {
        color: #212121;
        font-size: 12px;
        font-weight: 400;
    }

    .agrtrms {
        display: block;
        position: relative;
        padding: 0 10px;
        cursor: pointer;
    }

    .agrtrms input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
   .iagt input.chki {
        padding-left: 0;
    }
    .agrchckmrk {position: relative;
        border-radius: 4px;
        border: 1px solid #E3E6EF;
        background: #FFF;
        height: 18px;
        width: 18px;display: block;
    }
    .agrchckmrk:after {
        content: "";
        position: absolute;
        display: none;
        height: 10px;
        width: 10px;top: 50%;left: 50%;
        transform: translate(-50%,-50%);
        background: #994B00;
    }
    .agrtrms input:checked~.agrchckmrk {
        border-radius: 3px;
        border: 2px solid #994B00;
    }
    .agrtrms input:checked~.agrchckmrk:after {
        display: block;
    }
    .allpost_sect .user_dt h3 {}

.allpost_sect .allpost_inner h3 {
    font-size: 24px;
    margin-bottom: 24px;
}
.allpost_sect .allpost_inner h4 {
    font-size: 16px;
    margin-bottom: 15px;
}
.allpost_sect .allpost_inner p {
    font-size: 16px;
}

.allpost_inner {
    margin-top: 24px;
}
.allpost_sect .allpost_inner {
    display: grid;
    grid-template-columns: 1fr 0.6fr;
    gap: 40px;margin-top: 30px;
}

.allpost_sect .allpost_inner .lft_bx {}

.allpost_sect .allpost_inner .lft_bx h3 {
    color: #333;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
}

.allpost_sect .allpost_inner .lft_bx h4 {
    color: #333;
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.86px; 
    margin-bottom: 20px;
}

.allpost_sect .allpost_inner .lft_bx p {
    color: #333;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; 
}

.allpost_sect .allpost_inner .rgt_bx {}

.allpost_sect .allpost_inner .rgt_bx img {
    width: 100%;
}

.allpost_sect .user_avtar .allpost_det p {
    color: #333;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
}
.allpost_det h5 {
    color: #0A0A0A;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
}

.allpost_sect .user_avtar .user_dt .allpost_det {
    margin-top: 20px;
}

.allpost_sect .user_avtar .user_dt .head {
    border: 0;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}

.blogpost_list .card_bx {
    width: 100%;
    max-width: 70%;
    border-bottom: 1px solid #E6E6E6;
    margin-bottom: 15px;
    padding-bottom: 15px;
}

.blogpost_list .card_bx ul {
    margin: 10px -15px 0;
}

.blogpost_list .card_bx ul li {
    display: inline-flex;
    padding: 0 15px;
    gap: 10px;
}

.blogpost_list .card_bx ul li span.btn {
    border-radius: 41px;
    background: rgba(153, 75, 0, 0.20);
    color: #994B00;
    font-size: 12px;
    font-weight: 500;
    padding: 4px 20px;
}

.blogpost_list {
    margin-top: 45px;
}
.action_btn .btn-success {
    background: #0000;
    border: 0;
    padding: 0;
}
.action_btn .btn-check:checked+.btn, .action_btn .btn.active, .action_btn .btn.show, .action_btn .btn:first-child:active, .action_btn .btn:hover, .action_btn :not(.btn-check)+.btn:active, .table_inner table.table tbody tr .dropdown-item.active, .table_inner table.table tbody tr .dropdown-item:active {
    background-color: #fff;
    border: 0;
}
.action_btn .dropdown-menu.show {
    background: #fff;
    border-radius: 0;
    box-shadow: 0 0 4px 0 #00000040;
    min-width: 100px!important;
}
.action_btn .dropdown-menu.show .dropdown-item {
    color: #212121;
    font-size: 12px;
    font-weight: 400;
}
@media (min-width: 360px) and (max-width: 990px){
    .pagenation_item .pagenation_no{flex-wrap: wrap;}
    .Portfolio_list,.comp_info_wrapp ,.allpost_sect .allpost_inner{grid-template-columns:auto;}
    .pagenation_item{flex-wrap: wrap;}
    .table_sect .table_inner{overflow: auto;}
    .css-k008qs{flex-wrap: wrap;}
    .login_sect{display: grid;}
    .AllUsersView_sect .btn_grp .btn{padding:8px 15px;}
    .login_sect .left_bx img{width: 100%;height: auto;}
    .login_sect .rgt_bx{width: 100%;}
    .login_frm_sect{width: 100%;margin: 0;padding: 15px;}
    .login_sect .left_bx{width: 100%;}
    .form_crd h3{font-size: 26px;}
    .frm_frog .otp_grp .form-control{width: 30px;height: 30px;}
    .frm_frog{margin-top: 20px;}
    .chat_list,.grap_list{grid-template-columns: auto;}
    .table_grap{overflow: auto;}
    .grap_chart img{width: 100%;}
    .header_admin button.btn{padding: 5px;}
    .grap_list .grap_card{margin-bottom: 15px;}
    .header_admin button.btn,.header_admin .user_img, .header_admin .user_img img{width: 30px;height: 30px;}
    .header_admin .lft_bx h3{font-size: 16px;}
}
@media (min-width: 360px) and (max-width: 460px){
    .breadcrumb>li>a, .breadcrumb button{font-size: 12px;}
    .breadcrumb>li+li:after{font-size: 16px;}
    .pagenation_no button{    font-size: 12px;
        font-weight: 600;
        padding: 3px 10px;
        width: 122px;
        margin: 0 24px;}
        .pagenation_item .pagenation_no{    justify-content: center; gap: 15px;}
}


/* body{font-family: Plus Jakarta Sans!important;color: #141414!important;}
* { margin: 0px; padding: 0px; box-sizing: border-box; }
a{text-decoration: none;}
h1, h2, h3, h4, h5, h6, p, ul{margin: 0px; padding: 0px;}
:focus-visible { outline: none;}  */

.login_sect .w-full {
    display: flex;
    align-items: center;
}
.form_crd form {
    border: 0;
    background: #fff;
    padding: 0;
    margin: 0;
    width: 100%;
}

.login_frm_sect {background: #FFF;
    box-shadow: 10px 24px 54px 0px rgba(0, 0, 0, 0.06);
    /* margin-left: -10%; */
    padding: 80px;
    width: 100%;
    max-width: 80%;
    z-index: 1;}
.frm_frog .otp_grp {display: flex;align-items: center;gap: 10px;}
.frm_frog .otp_grp .form-control {background: #F5EDE5;border: 1px solid #994B00; border-radius: 10px;width: 120px;height: 40px;display: flex;padding: 5px;align-items: center;text-align: center;justify-content: center;color: #171724;font-size: 16px;font-weight: 600;}
.frm_frog .pass_rest {color: #A3A3A3;font-size: 14px;font-weight: 400;}
.frm_frog .pass_rest a{color: #000;font-size: 14px;font-weight: 400; }
.frm_frog {display: flex;align-items: center;justify-content: space-between;margin-top: 45px;}
.form_crd h3 {color: #464646;font-size: 32px;font-weight: 600;margin-bottom: 20px;}
.form_crd .form-group {}
.form_crd .form-group .form-control {border-radius: 5px;border: 1px solid #EBEDF4;background: #FFF;height: 45px;width: 100%;padding: 8px 15px;color: #A3A3A3;font-size: 16px;font-weight: 400;line-height: 23px; } 
.form-group {}
.form_crd .form-group label {color: #464646;font-size: 14px;font-weight: 500;display: block;margin-bottom: 5px;}
.frm_frog .otp_grp .form-control:hover {background: #fff;}
.login_frm_sect .inner_bdy {border-radius: 5px;background: #FFF;box-shadow: 0px 1px 4px 0px #E5E9F2;padding: 30px; }
.login_frm_sect .inner_bdy .btn {display: block;text-align: center; border-radius: 77px;color: #FFF;font-size: 20px;font-weight: 500;text-transform: uppercase;background: #994B00;width: 100%;border: 0;padding: 8px 15px;margin-top: 55px;}
.heading_title {text-align: right;}
.heading_title a{color: #818181;text-align: right;font-size: 18px;font-weight: 600;letter-spacing: 0.09px;text-transform: capitalize;}
 .heading_title {position: absolute;top: 20px;right: 21px;}
.login_singup a {color: #FFF;font-size: 18px;font-weight: 500;display: flex;align-items: center;gap: 10px;}
.login_singup {padding: 15px  30px;margin-top: 70%;}
.login_sect .left_bx img {width: 100%;height: 100vh;
}
@media (min-width: 360px) and (max-width: 990px){
    .heading_title a{color: #fff;}
    .login_frm_sect{max-width: 100%; margin: 0 15px;}
    .login_sect{display: grid;}
     .login_frm_sect{width: 100%;margin: 0;padding: 15px;}
 
    .form_crd h3{font-size: 26px;}
    .frm_frog .otp_grp .form-control{width: 30px;height: 30px;}
    .frm_frog{margin-top: 20px;}
 
}


.modal.show .modal-dialog {
    top: 200px;
}


a.logo_navbar img {
    height: 56px !important;
}

.card_bx.spam {
    background: #fce5e5;
}
